import React from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import { Loader } from '../Loader/Loader';
import { StatGaugeItem } from './StatGaugeItem';

interface ICrimeReportProps {
	loading: boolean;
	items: { name: string; value: string }[];
}

export const CrimeReport: React.FC<ICrimeReportProps> = ({
	loading,
	items,
}) => {
	return (
		<Box bg="white" p={3} boxShadow="md">
			<Text fontSize="large" mb="5px" fontWeight={700}>
				Crime Report
			</Text>
			{loading ? (
				<Loader centerHeight="230px" />
			) : (
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 4 }}
					gap="20px"
					mb="20px">
					{items.map((elem, index) => (
						<StatGaugeItem
							key={index}
							name={elem.name}
							value={elem.value}
						/>
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};
