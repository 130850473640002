export const colors = {
	blue: {
		50: '#4A5991',
		100: '#1C2959',
		200: '#0F1B49',
		300: '#10162C',
		400: '#7195D1',
	},
	mainBg: '#F1F4F9',
	success: '#08BB4B',
	inactiveText: '#7E788F',
	successButton: {
		50: '#DCF4E5',
		500: '#07A643',
		550: '#06933B',
		600: '#179848',
	},
	dangerButton: {
		500: '#C14B4B',
		600: '#ab3b3b',
	},
	primaryButton: {
		500: '#1C2959',
		600: '#101b3c',
	},
	secondaryButton: {
		500: '#4A5991',
		600: '#3b4977',
	},
	inactiveBlue: '#4A5991',
	dropBlue: '#BDCDE9',
	blueHover: {
		100: '#a7b4e5',
	},
	textGray: {
		100: '#bebac9',
		200: '#8E8E8E',
	},
	bgGray: {
		50: '#BDCDE9',
		100: '#d9d9d9',
		200: '#D7E1F1',
	},
	valueRed: '#F35050',
	dropBlueOpacity: {
		500: 'rgba(189, 205, 233, 0.5)',
		600: 'rgba(189, 205, 233, 0.6)',
	},
	dashboardYellow: '#ECCE2E',
	lightBlue: {
		100: '#E4EAF5',
		200: '#EBF0F9',
		300: '#97B1DD',
		400: '#22316B',
	},
	lightPink: {
		100: '#FBEDED',
	},
	lightGreen: {
		100: '#F0FAF4',
	},
	danger: {
		100: '#C14B4B',
		200: '#A33838',
	},
};
