/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useDebounce } from '../../hooks';
import { AutoComplete, CrimeReport, Rents } from '../../components';
import { PreciselyAutocompleteItem } from '../../types';
import { PreciselyPropertyDetailsResponse } from './types';
import { PropertyAttributes } from './components';
import { usePropertyAutocompleteQuery, usePropertyDetails } from './queries';

const PropertyLookup: React.FC = () => {
	const [addressQuery, setAddressQuery] = useState('');
	const [address, setAddress] = useState<PreciselyAutocompleteItem>({
		coordinates: [0, 0],
		text: '',
	});
	const [addressesSuggestions, setAddressesSuggestions] = useState<
		PreciselyAutocompleteItem[]
	>([]);
	const [propertyDetails, setPropertyDetails] =
		useState<PreciselyPropertyDetailsResponse | null>(null);

	const debouncedSearchQuery = useDebounce(addressQuery, 500);

	const { isLoading, refetch } = usePropertyAutocompleteQuery(
		debouncedSearchQuery,
		setAddressesSuggestions,
	);

	const { isLoading: detailsLoading, refetch: detailsRefetch } =
		usePropertyDetails(address, setPropertyDetails);

	useEffect(() => {
		if (debouncedSearchQuery) {
			refetch();
		}
	}, [debouncedSearchQuery]);

	useEffect(() => {
		if (address.text) {
			detailsRefetch();
		}
	}, [address]);

	return (
		<Box p="4">
			<Grid
				templateColumns={{ base: '1fr', lg: '65% 1fr' }}
				templateRows={{ base: 'repeat(1fr, 4)', lg: 'auto auto 1fr' }}
				templateAreas={{
					base: `"search"
					"report"
					"rents"
					"info"`,
					lg: `"search info"
				"report info"
				"rents info"`,
				}}
				columnGap="15px"
				rowGap="20px">
				<GridItem gridArea="search">
					<Box>
						<AutoComplete
							label="Search by property address"
							value={addressQuery}
							placeholder="Search..."
							setValue={setAddressQuery}
							isLoading={isLoading}
							clearItems={() => {
								setAddressesSuggestions([]);
								setAddressQuery('');
							}}
							onSelectItem={item => {
								if (item) {
									setAddress(addressesSuggestions[item.id]);
								} else {
									setAddress({
										coordinates: [0, 0],
										text: '',
									});
								}
							}}
							items={addressesSuggestions.map((elem, index) => ({
								id: index,
								name: elem.text,
							}))}
						/>
					</Box>
				</GridItem>
				<GridItem gridArea="report">
					<CrimeReport
						loading={detailsLoading}
						items={
							propertyDetails?.crimes?.length
								? propertyDetails?.crimes[0].crimeIndexTheme.indexVariable.map(
										elem => ({
											name: elem.name,
											value: elem.category,
										}),
								  )
								: []
						}
					/>
				</GridItem>
				<GridItem gridArea="info">
					<PropertyAttributes
						loading={detailsLoading}
						data={propertyDetails?.attributes}
					/>
				</GridItem>
				<GridItem gridArea="rents">
					<Rents
						loading={detailsLoading}
						studio={propertyDetails?.fmr0 || 0}
						br1={propertyDetails?.fmr1 || 0}
						br2={propertyDetails?.fmr2 || 0}
						br3={propertyDetails?.fmr3 || 0}
						br4={propertyDetails?.fmr4 || 0}
						countyName={propertyDetails?.countyName || ''}
						stateCode={propertyDetails?.stateCode || ''}
					/>
				</GridItem>
			</Grid>
		</Box>
	);
};

export default PropertyLookup;
