import React from 'react';
import { Box, Text, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { Loader } from '../Loader/Loader';

interface IRentsProps {
	loading: boolean;
	studio: number;
	br1: number;
	br2: number;
	br3: number;
	br4: number;
	countyName: string;
	stateCode: string;
}

export const Rents: React.FC<IRentsProps> = ({
	loading,
	br1,
	br2,
	br3,
	br4,
	studio,
	countyName,
	stateCode,
}) => {
	return (
		<Box>
			<Text fontSize="large" fontWeight={700} mb="10px">
				Section 8 Rents{' '}
				{countyName || stateCode ? `(${countyName}, ${stateCode})` : ''}
			</Text>
			{loading ? (
				<Loader
					centerHeight="300px"
					centerProps={{
						bg: 'white',
						border: '1px solid',
						borderColor: 'gray.100',
					}}
				/>
			) : (
				<Table
					variant="simple"
					border="1px solid"
					borderColor="gray.100"
					mb="15px"
					bg="white">
					<Thead>
						<Tr>
							<Th>Bedrooms</Th>
							<Th>Fair Market Rent</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td>Studio</Td>
							<Td>{studio ? `$${studio}` : ''}</Td>
						</Tr>
						<Tr>
							<Td>1br</Td>
							<Td>{br1 ? `$${br1}` : ''}</Td>
						</Tr>
						<Tr>
							<Td>2br</Td>
							<Td>{br2 ? `$${br2}` : ''}</Td>
						</Tr>
						<Tr>
							<Td>3br</Td>
							<Td>{br3 ? `$${br3}` : ''}</Td>
						</Tr>
						<Tr>
							<Td>4br</Td>
							<Td>{br4 ? `$${br4}` : ''}</Td>
						</Tr>
					</Tbody>
				</Table>
			)}
		</Box>
	);
};
