import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';
import { API_CHAT } from '../../../api/api.base';

export const useSaveDeviceToken = () => {
	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationKey: [MessengerQueryKeys.SAVE_DEVICE_TOKEN],
		mutationFn: payload =>
			API_CHAT.post('/api/app/push-notification/token/add', {
				deviceToken: payload,
				phoneOS: 2,
			}),

		onError(error) {
			console.log(error, 'save device token error');
		},
	});
};
