import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { ApiResponse, PreciselyAutocompleteItem } from '../../../types';
import API from '../../../api/api.base';

export const usePropertyAutocompleteQuery = (
	debouncedSearchQuery: string,
	setAddressesSuggestions: Dispatch<
		SetStateAction<PreciselyAutocompleteItem[]>
	>,
) => {
	return useQuery<
		ApiResponse<{
			models: PreciselyAutocompleteItem[];
		} | null>
	>(
		['property-research', debouncedSearchQuery],
		() =>
			API.get(
				`/api/app/precisely/property/autocomplete?Address=${debouncedSearchQuery}`,
				{},
			),
		{
			enabled: false,
			onSuccess(data) {
				setAddressesSuggestions(data.value?.models || []);
			},
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};
