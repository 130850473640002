/* eslint-disable indent */
import React from 'react';
import Downshift from 'downshift';
import { Box, List, ListItem, Text } from '@chakra-ui/react';
import { InputText } from '../InputText/InputText';
import { Loader } from '../Loader/Loader';

interface IAutoCompleteProps {
	errorMsg?: string;
	label?: string;
	onSelectItem: (item: { id: number; name: string }) => void;
	value: string;
	setValue: (value: string) => void;
	items: { id: number; name: string }[] | undefined;
	placeholder?: string;
	clearItems?: VoidFunction;
	isLoading?: boolean;
	rightIcon?: JSX.Element;
	onRightIconClick?: VoidFunction | null;
	name?: string;
	clearValues?: string[];
	disabledInputs?: {
		[key: string]: boolean;
	};
	clearInputs?: (
		e: React.ChangeEvent<HTMLInputElement>,
		fn: any,
		name: string,
	) => void;
}

export const AutoComplete: React.FC<IAutoCompleteProps> = ({
	errorMsg,
	label,
	onSelectItem,
	items,
	placeholder,
	// clearItems,
	isLoading,
	value,
	setValue,
	rightIcon,
	onRightIconClick,
	name,
	clearValues,
	disabledInputs,
	clearInputs,
}) => {
	return (
		<Downshift
			onChange={selection => {
				onSelectItem(selection);
				// setValue('');
				// clearItems?.();
			}}
			onStateChange={obj => {
				if (obj.type === Downshift.stateChangeTypes.changeInput) {
					setValue(obj.inputValue || '');
				}
			}}
			itemToString={item => (item ? item.name : '')}>
			{({
				getInputProps,
				getItemProps,
				getMenuProps,
				highlightedIndex,
				isOpen,
				clearSelection,
				toggleMenu,
				closeMenu,
			}) => (
				<div
					style={{
						display: 'inline-block',
						width: '100%',
						position: 'relative',
					}}>
					{/* @ts-ignore */}
					<InputText
						{...getInputProps({
							onChange: e => {
								if (clearInputs && name) {
									clearInputs(e, clearSelection, name);
								}
							},

							onClick: e => {
								if (e.target instanceof HTMLInputElement) {
									toggleMenu();
								}
							},
						})}
						placeholder={placeholder}
						label={label}
						errorMsg={errorMsg}
						bg="white"
						rightIcon={rightIcon}
						onRightIconClick={onRightIconClick}
						clearSelection={clearSelection}
						name={name}
						clearValues={clearValues}
						disabledInputs={disabledInputs}
						toggleMenu={toggleMenu}
					/>
					<Box
						bg="#eee"
						boxShadow="md"
						padding={0}
						w="100%"
						position="absolute"
						zIndex={50}>
						{isLoading ? (
							<Loader centerHeight={'100px'} spinnerSize={'md'} />
						) : (
							<List
								{...getMenuProps()}
								maxHeight="150px"
								overflowY="scroll">
								{isOpen
									? items?.map((item, index) => (
											<ListItem
												key={`${item.name}${index}`}
												cursor="pointer"
												onClick={closeMenu}
												px={'12px'}
												py={'8px'}
												backgroundColor={
													highlightedIndex === index
														? 'lightgray'
														: 'white'
												}
												{...getItemProps({
													key: `${item.name}${index}`,
													item,
													index,
												})}>
												<Text
													noOfLines={1}
													variant={'bodyMedium'}>
													{item.name}
												</Text>
											</ListItem>
									  ))
									: null}
							</List>
						)}
					</Box>
				</div>
			)}
		</Downshift>
	);
};
