import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { PreciselyPropertyAttributes } from '../types';
import { Loader } from '../../../components';
import { formatDollars } from '../../../utils';

interface IPropertyAttributesProps {
	loading: boolean;
	data?: PreciselyPropertyAttributes | null;
}

export const PropertyAttributes: React.FC<IPropertyAttributesProps> = ({
	data,
	loading,
}) => {
	return loading ? (
		<Loader
			centerHeight="680px"
			centerProps={{
				bg: 'white',
				border: '1px solid',
				borderColor: 'gray.100',
				mt: '25px',
				mb: '15px',
			}}
		/>
	) : (
		<Table
			mt="25px"
			variant="simple"
			border="1px solid"
			borderColor="gray.100"
			mb="15px"
			bg="white">
			<Thead>
				<Tr>
					<Th>Property field</Th>
					<Th>Latest data</Th>
				</Tr>
			</Thead>
			<Tbody>
				<Tr>
					<Td fontWeight={600}>Bedrooms</Td>
					<Td>{data?.bedrooms || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Baths</Td>
					<Td>{data?.baths || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Property APN</Td>
					<Td>{data?.propApn || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Property Square Feet</Td>
					<Td>{data?.propSqFt || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Building Square Feet</Td>
					<Td>{data?.buildgSqFt || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Year Built</Td>
					<Td>{data?.builtYear || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Fireplace</Td>
					<Td>{data?.firePlace || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Assessed Value</Td>
					<Td>
						{data?.assessedValue
							? formatDollars.format(+data?.assessedValue || 0)
							: ''}
					</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Market Value</Td>
					<Td>
						{data?.marketValue
							? formatDollars.format(+data?.marketValue || 0)
							: ''}
					</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Tax Amount</Td>
					<Td>
						{data?.taxAmount
							? formatDollars.format(+data?.taxAmount)
							: ''}
					</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Last Sale</Td>
					<Td>{data?.priorSaleDate || ''}</Td>
				</Tr>
				<Tr>
					<Td fontWeight={600}>Tax Rate Code</Td>
					<Td>{data?.taxRateCode || ''}</Td>
				</Tr>
			</Tbody>
		</Table>
	);
};
