import { useInfiniteQuery } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ConversationHistoryItem } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetChannelMessages = (
	channelId?: number | null,
	offset?: number,
) => {
	const fetchMessages = async ({ pageParam = 1, offset = 0 }) => {
		const res: ApiResponse<{
			count: number;
			messages: ConversationHistoryItem[];
			userLastReadDateTime: string;
			unreadCount: number;
		}> = await API_CHAT.get('/api/app/channel/messages', {
			PageSize: 30,
			CurrentPage: pageParam,
			ChannelId: channelId,
			AdditionalOffset: offset,
		});

		return (
			res?.value || {
				count: 0,
				messages: [],
				unreadCount: 0,
				userLastReadDateTime: new Date().toISOString(),
			}
		);
	};

	return useInfiniteQuery({
		queryKey: [MessengerQueryKeys.GET_CHANNEL_MESSAGES, channelId],
		queryFn: ({ pageParam }) =>
			fetchMessages({
				pageParam,
				offset,
			}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.messages?.length === 30
				? allPages.length + 1
				: null;
		},
		enabled: !!channelId,
		refetchOnWindowFocus: false,
	});
};
