import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { ApiResponse, PreciselyAutocompleteItem } from '../../../types';
import API from '../../../api/api.base';
import { PreciselyPropertyDetailsResponse } from '../types';

export const usePropertyDetails = (
	address: PreciselyAutocompleteItem,
	setPropertyDetails: Dispatch<
		SetStateAction<PreciselyPropertyDetailsResponse | null>
	>,
) => {
	return useQuery<ApiResponse<PreciselyPropertyDetailsResponse>>(
		['property-details', address],
		() =>
			API.get(
				`/api/app/precisely/property/details?Longitude=${address.coordinates[0]}&Latitude=${address.coordinates[1]}&Address=${address.text}`,
				{},
			),
		{
			enabled: false,
			onSuccess(data) {
				setPropertyDetails(data.value);
			},
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
};
