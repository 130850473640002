import {
	DateFilterEnum,
	InvoiceFieldOrder,
	InvoiceFilterStatus,
	InvoiceMainFilterEnum,
	InvoiceStatusEnum,
	InvoiceTypeEnum,
} from '../types';
import dayjs from 'dayjs';

export const INVOICE_STATUS_OPTIONS = [
	{
		value: InvoiceStatusEnum.UnPaid,
		label: 'Unpaid',
	},
	{
		value: InvoiceStatusEnum.Paid,
		label: 'Paid',
	},
	{
		value: InvoiceStatusEnum.Cancelled,
		label: 'Cancelled',
	},
];

export const defaultInvoiceValues = {
	tenant: null,
	invoiceType: null,
	invoiceStatus: INVOICE_STATUS_OPTIONS[0],
	paymentMethod: null,
	invoiceDate: dayjs().format('YYYY-MM-DD'),
	invoiceDueDate: '',
	paidDate: '',
	invoiceItems: [
		{
			description: '',
			amount: null,
		},
	],
	isSendToEmail: false,
};

export const DATE_FILTER_OPTIONS = [
	{
		value: DateFilterEnum.CurrentMonth,
		label: 'Current Month',
	},
	{
		value: DateFilterEnum.PreviousMonth,
		label: 'Previous Month',
	},
	{
		value: DateFilterEnum.AllTime,
		label: 'All Time',
	},
	{
		value: DateFilterEnum.SpecificDates,
		label: 'Specific Dates',
	},
];

export const INVOICE_STATUS_FILTER_OPTIONS = [
	{
		value: 0,
		label: 'All Statuses',
	},
	{
		value: InvoiceFilterStatus.Paid,
		label: 'Paid',
	},
	{
		value: InvoiceFilterStatus.UnpaidAll,
		label: 'Unpaid',
	},
];

export const INVOICE_UNPAID_FILTER_OPTIONS = [
	{
		value: InvoiceFilterStatus.UnpaidAll,
		label: 'All',
	},
	{
		value: InvoiceFilterStatus.UnpaidOnTime,
		label: 'On Time',
	},
	{
		value: InvoiceFilterStatus.UnpaidOverdue,
		label: 'Overdue',
	},
];

export const INVOICE_MAIN_FILTER_OPTIONS = [
	{
		label: 'By Property/Unit',
		value: InvoiceMainFilterEnum.Property,
	},
	{
		label: 'By Tenant',
		value: InvoiceMainFilterEnum.Name,
	},
];

export const INVOICE_TABLE_HEAD_BUTTONS = [
	{
		name: 'No',
		type: InvoiceFieldOrder.Number,
	},
	{
		name: 'Tenant',
		type: InvoiceFieldOrder.Tenant,
	},
	{
		name: 'Invoice Date',
		type: InvoiceFieldOrder.InvoiceDate,
	},
	{
		name: 'Due Date',
		type: InvoiceFieldOrder.DueDate,
	},
	{
		name: 'Paid Date',
		type: InvoiceFieldOrder.PaidDate,
	},
	{
		name: 'Amount',
		type: InvoiceFieldOrder.Amount,
		isNumeric: true,
	},
];

export const INVOICES_TABS = [
	{
		name: 'All',
		value: 0,
	},
	{
		name: 'Security Deposit',
		value: InvoiceTypeEnum.SecurityDeposit,
	},
	{
		name: 'Private',
		value: InvoiceTypeEnum.Private,
	},
	{
		name: 'Trillium',
		value: InvoiceTypeEnum.Trillium,
	},
	{
		name: 'Section 8',
		value: InvoiceTypeEnum.Section8,
	},
];
